<template>
    <section class="tables new-updated-design">
        <vue-snotify></vue-snotify>
        <div class="col-md-12 d-flex align-items-stretch mb-3">
            <div class="row flex-grow">
                <div class="col-12 p-0">
                    <div class="d-flex justify-content-between new-d-flex">
                        <b-btn v-on:click="addSpot" variant="primary">{{ $t('add-spot-btn') }}</b-btn>
                        <div class="spot-btns d-flex align-items-center">
   
                            <div class="spot-btns">
                                <b-btn class="mr-2" v-on:click="ImportSpots" variant="primary">{{
                                    $t('import-spots-btn') }}</b-btn>
                                <b-btn v-on:click="UploadBulkImages" variant="primary">{{ $t('upload-bulk-images') }}</b-btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 stretch-card">
                <div class="card">
                    <div class="card-header d-flex space-btw">
                        <h4 class="card-title">{{ $t('new-spots') }}</h4>                
                        <div v-if="version_id == 2 || version_id == 3">
                            <label class="text-left mr-4" for="inline-form-custom-select-pref">{{
                                $t('apply-weather-to-all-spots') }}</label>
                            <toggle-button @change="applyWeatherToSpots" v-model="require_weather" class="switchColor" />
                        </div>
                    </div>
                    <div class="card-body new-card-body flex">
                        <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
                            @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange"
                            :totalRows="totalRecords" :isLoading.sync="isLoading"
                            :pagination-options="paginationOptions"
                            :rows="rows" :columns="tableColumns" @on-search="searchFn"
                            :search-options="{ enabled: true, searchFn: searchFn }" class="my-response-table"
                            :line-numbers="true">
                            <template slot="table-row" slot-scope="props">
                                <span v-if="props.column.field === 'action'" class="text-nowrap">
                                    <toggle-button v-model="spots.find(p => p.id === props.row.id).spot_status"
                                        @change="changeStatus(props.row.id, $event)" :sync="true"
                                        class="mr-1 switchColor" />
                                    <b-button size="sm" @click="editSpot(props.row.id, props.row.spot_type)"
                                        class="mr-1 btn-info text-white">{{ $t('edit-btn') }}</b-button>
                                    <b-button size="sm" @click="deleteClientSpot(props.row.id)"
                                        class="mr-1 btn-danger text-white">{{ $t('delete-btn') }}</b-button>
                                    <toggle-button v-model="spots.find(p => p.id === props.row.id).is_shareable"
                                        @change="changeShareableStatus(props.row.id, $event)" :sync="true"
                                        class="mr-1 switchColor" />
                                </span>
                            </template>
                        </vue-good-table>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="version_id == 2 || version_id == 3">

            <div class="row pt-5">
                <div class="col-lg-12 stretch-card">
                    <div class="card">
                        <div class="card-header d-flex">
                            <h4 class="card-title">{{ $t('spots-by-category') }}</h4>
                            <div v-if="version_id == 2 || version_id == 3">
                                <div class="pl-2">
                                    <select class="form-control form-select-sm" id="categorySelect" style="height: 38px" v-model="selectedCategory">
                                        <option value="" selected>{{ $t('spots-by-category') }}</option>
                                        <option v-for="category in categories" :value="category.id" :key="category.id">
                                            {{ category.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="card-body new-card-body flex">
                            <vue-good-table mode="remote" @on-page-change="onPageChange1" @on-sort-change="onSortChange1"
                                @on-column-filter="onColumnFilter1" @on-per-page-change="onPerPageChange1"
                                :totalRows="totalRecords1" :isLoading.sync="isLoading1"
                                :pagination-options="{ enabled: true, dropdownAllowAll: false, perPageDropdown: [10, 20, 50, 100, 200], }"
                                :rows="rows1" :columns="tableColumns1" @on-search="searchFn1"
                                :search-options="{ enabled: true, searchFn1: searchFn1 }" class="my-response-table"
                                :line-numbers="true">
                                <template slot="table-row" slot-scope="props">
                                    <span v-if="props.column.field === 'action'" class="text-nowrap">
                                        <toggle-button v-model="spots.find(p => p.id === props.row.id).spot_status"
                                            @change="changeStatus(props.row.id, $event)" :sync="true"
                                            class="mr-1 switchColor" />
                                        <b-button size="sm" @click="editSpot(props.row.id, props.row.spot_type)"
                                            class="mr-1 btn-info text-white">{{ $t('edit-btn') }}</b-button>
                                        <b-button size="sm" @click="deleteClientSpot(props.row.id)"
                                            class="mr-1 btn-danger text-white">{{ $t('delete-btn') }}</b-button>
                                        <toggle-button v-model="spots.find(p => p.id === props.row.id).is_shareable"
                                            @change="changeShareableStatus(props.row.id, $event)" :sync="true"
                                            class="mr-1 switchColor" />
                                    </span>
                                </template>
                            </vue-good-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </section>
</template>

<script>
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
import API from "@/api";
import Snotify, { SnotifyPosition } from "vue-snotify";
import { validationMixin } from "vuelidate";
import {
    required,
    minLength,
    email,
    requiredIf,
    sameAs
} from "vuelidate/lib/validators";
import moment from "moment";

const options = {
    toast: {
        position: SnotifyPosition.rightTop
    }
};
Vue.use(Snotify, options);
Vue.use(SortedTablePlugin, {
    ascIcon: '<i class="ti-arrow-down"></i>',
    descIcon: '<i class="ti-arrow-up"></i>'
});
export default {
    name: "Spotters",
    mixins: [validationMixin],
    data() {
        return {
            require_weather: null,
            version_id: null,
            is_pro_sync: null,
            isDisabled: false,
            selected: true,
            unSelected: false,
            serverParams: {
                // a map of column filters example: {name: 'john', age: '20'}
                client_id: JSON.parse(localStorage.getItem("client_id")),
                columnFilters: {},
                sort: [
                    {
                        field: "id", // example: 'name'
                        type: "desc" // 'asc' or 'desc'
                    }
                ],
                page: 1, // what page I want to show
                perPage: 4, // how many items I'm showing per page
            },
            serverParams1: {
                // a map of column filters example: {name: 'john', age: '20'}
                client_id: JSON.parse(localStorage.getItem("client_id")),
                columnFilters: {},
                sort: [
                    {
                        field: "id", // example: 'name'
                        type: "desc" // 'asc' or 'desc'
                    }
                ],

                page1: 1, // what page I want to show
                perPage1: 10, // how many items I'm showing per page
                selectedCategory: null
            },
            columns: [
                {
                    label: "Spot ID",
                    field: "spot_id",
                    filterable: true
                },
                {
                    label: "Date Spotted",
                    field: "spot_date",
                    sortable: true,
                    formatFn: this.formatDate
                },
                {
                    label: "User",
                    field: "user_name",
                    filterable: true,
                    filterOptions: {
                            styleClass: 'class1',
                            enabled: true,
                            placeholder: 'Select User',
                            filterValue: '',
                            filterDropdownItems: this.user_names,
                            filterFn: this.columnFilterFn,
                            trigger: 'enter',
                        },
                },
                {
                    label: "Type",
                    field: "spot_name",
                    sortable: true
                },
                {
                    label: "Location",
                    field: "location_name",
                    sortable: true
                },
                {
                    label: "Type",
                    field: "media_types",
                    sortable: true,
                    formatFn: this.formatType
                },
                {
                    label: "Offline Spot",
                    field: "offline_mobile_spot",
                    sortable: true,
                    formatFn: this.OfflineModeStatus,
                    filterOptions: {
                        styleClass: 'class1',
                        enabled: true,
                        placeholder: '-',
                        filterValue: '',
                        filterDropdownItems: [
                            { value: '0', text: 'No' },
                            { value: '1', text: 'Yes' },
                        ],
                        filterFn: this.columnFilterFn,
                        trigger: 'enter',
                    },
                },
                {
                    label: "Desktop Spots",
                    field: "desktop_spot",
                    sortable: true,
                    formatFn: this.desktopSpots,
                    filterOptions: {
                        styleClass: 'class1',
                        enabled: true,
                        placeholder: '-',
                        filterValue: '',
                        filterDropdownItems: [
                            { value: '0', text: 'No' },
                            { value: '1', text: 'Yes' },
                        ],
                        filterFn: this.columnFilterFn,
                        trigger: 'enter',
                    },
                },
                {
                    label: "Approve/ Action /Share",
                    field: "action",
                    sortable: false
                }
            ],
            isLoading: false,
            isLoading1: false,
            rows: [],
            totalRecords: 0,
            spots: [],
            categories : [],
            selectedCategory: '',
            user_names: [],
            user_data: [],
            spottype: [],
            locations: [],
            rows1: [],
            totalRecords1: 0,
            paginationOptions: {
                enabled: true,
                dropdownAllowAll: false,
                perPageDropdown: []
            }
        };
    },
    methods: {
        getUserDropdownOptions() {
            this.spottype = Array.from(new Set(this.spots_types.map(row => ({ value: row.id, text: row.name }))));
            this.locations = Array.from(new Set(this.locations.map(row => ({ value: row.id, text: row.name }))));
        },
        deleteClientSpot(e) {
            this.$swal({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!"
            }).then(result => {
                if (result.value) {
                    let data = {
                        id: e
                    };
                    API.deleteClientSpot(
                        e,
                        data => {
                            this.loadItems();
                            this.$snotify.success('Spot deleted successfully.')
                        },
                        err => { }
                    );
                }
            });
        },
        addSpotter() {
            this.$router.push({ name: "addSpotter" });
        },
        editSpot(e, type) {
            this.$router.push({
                name: "edit_spots",
                params: { id: e, type: type }
            });
        },
        ImportSpots(e, type) {
            this.$router.push({ name: "import_spots" });
        },
        UploadBulkImages(e, type) {
            this.$router.push({ name: "upload_spot_images" });
        },
        getFilterCategorie() {
            const data = {
                client_id: JSON.parse(localStorage.getItem('client_id')),
            }
            API.getFilterCategories(
                data,
                data => {
                    const categories = data.data.map(category => ({
                        id: category.id,
                        name: category.name,
                        questions: category.questions
                    }));
                    this.categories = categories;
                    this.$v.form.$reset();
                    this.checkOrder();
                },
                err => {
                }
                )
            },
            getUserDropdownOptionslist() {
            this.isLoading = true;
            API.getUserDropdownOptionslists(this.serverParams,
                data => {
                    this.isLoading = false;
                    this.user_data = data.records;
                    this.spots_types = data.spotstype;
                    this.locations = data.spotslocation;
                    this.getUserDropdownOptions();
                },
                err => {
                }
            )
        },
        loadItems() {
            this.isLoading = true;
            API.getAllSpots(
                this.serverParams,
                data => {
                    this.isLoading = false;
                    this.version_id = data.version_id;
                    this.is_pro_sync = data.is_pro_sync;
                    console.log('this.version_id  :', this.version_id );
                    console.log('this.prosync  :', this.is_pro_sync );
                    this.totalRecords = data.totalRecords;
                    this.require_weather = data.require_weather;
                    this.rows = data.data.data;
                    this.rows.forEach(q => {
                        const data = {
                            id: q.id,
                            spot_status: q.spot_status === "A" ? true : false,
                            is_shareable: q.is_shareable === 1 ? true : false

                        };
                        this.spots.push(data);
                    });
                        this.paginationOption();
                    },
                    err => { }
                );
            },
        loadItems1() {
            this.isLoading1 = true;
            API.filterByCategory(
                this.serverParams1,
                data => {
                    this.isLoading1 = false;
                    this.version_id = data.version_id;
                    console.log('this.version_id  :', this.version_id);
                    this.totalRecords1 = data.totalRecords;
                    this.require_weather1 = data.require_weather;

                    this.rows1 = data.data.data;
                    this.rows1.forEach(q => {
                        const data = {
                            id: q.id,
                            spot_status: q.spot_status === "A" ? true : false,
                            is_shareable: q.is_shareable === 1 ? true : false
                        };
                        this.spots.push(data);
                    });
                    this.paginationOption();
                },
                err => { }
            );
        },
        formatType(mediaTypes) {
            if (mediaTypes !== null) {
                if(mediaTypes.includes("image")) {
                    if(mediaTypes.includes("audio")) {
                        return "Photo / Audio";
                    } else {
                        return "Image";
                    }
                } else if (mediaTypes.includes("audio")) {
                    return "Audio ";
                } else {
                    return "None";
                }
            } else {
                return "";
            }
        },
        formatDate(value) {
            if (value) {
                return moment(String(value)).format("YYYY-MM-DD");
            }
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        updateParams1(newProps) {
            this.serverParams1 = Object.assign({}, this.serverParams1, newProps);
        },
        onPageChange(params) {
            this.updateParams({ page: params.currentPage });
            this.loadItems();
        },
        onPageChange1(params) {
            this.updateParams1({ page1: params.currentPage });
            this.loadItems1();
        },
        onPerPageChange(params) {
            this.updateParams({ perPage: params.currentPerPage });
            this.updateParams({ page: 1 });
            this.loadItems();
        },
        onPerPageChange1(params) {
            this.updateParams1({ perPage1: params.currentPerPage });
            this.updateParams1({ page1: 1 });
            this.loadItems1();
        },
        onSortChange(params) {
            this.updateParams({
                sort: [
                    {
                        type: params[0].type,
                        field: params[0].field
                    }
                ]
            });
            this.loadItems();
        },
        onSortChange1(params) {
            this.updateParams1({
                sort: [
                    {
                        type: params[0].type,
                        field: params[0].field
                    }
                ]
            });
            this.loadItems1();
        },
        resetQuestionFilters(){
            this.columnFilters.question_one = null
            this.columnFilters.question_two = null;
            this.columnFilters.question_three = null;
            this.columnFilters.question_four = null;
        },
        handleCategorySelection(selectedCategory) {
            this.serverParams1.selectedCategory = selectedCategory;
            this.resetQuestionFilters();
            this.loadItems1();
            console.log('Selected category ID:', selectedCategory);
        },
        onColumnFilter(params) {
            this.updateParams(params);
            this.loadItems();
        },
        onColumnFilter1(params) {
            this.updateParams1(params);
            this.loadItems1();
        },
        searchFn(params) {
            this.updateParams({ searchTerm: params });
            this.loadItems();
            this.isLoading = false;
        },
        searchFn1(params) {
            this.updateParams1({ searchTerm1: params });
            this.loadItems1();
            this.isLoading1 = false;
        },
        formatStatus(status) {
            if (status === "A") {
                return true;
            } else {
                return false;
            }
        },
        OfflineModeStatus(offline_mobile_spot) {
            if (offline_mobile_spot == 1) {
                return 'Yes';
            } else {
                return 'No';
            }
        },
        desktopSpots(desktop_spot) {
            if (desktop_spot == 1) {
                return 'Yes';
            } else {
                return 'No';
            }
        },
        formatUser(value) {},
        formatLocation(value) {
            return value.name;
        },

        applyWeatherToSpots() {
            const data = {
                client_id: JSON.parse(localStorage.getItem('client_id')),
                require_weather: this.require_weather
            };
            API.applyWeatherToSpots(
                data,
                data => {
                if (data.status == 200) {
                    this.require_weather = data.data.require_weather;
                    this.$snotify.success(data.message);
                    console.log('this.require_weather:', this.require_weather);
                }
                },
                error => {
                console.log(error);
                }
            );
        },
        changeShareableStatus(id, event) {
            console.log('IDS',id, event.value);
            const data = {
                id: id,
                is_shareable: event.value === true ? 1 : 0
              
            };
            API.updateSpotShareable(
                data,
                data => {
                    if (data.status === 200) {
                        this.$snotify.success(data.message);
                    }
                },
                err => {}
            );
        },
        changeStatus(id, event) {
            console.log('DD',id, event.value);
            const data = {
                id: id,
                status: event.value === true ? "A" : "I"
            };
            API.updateSpotStatus(
                data,
                data => {
                    if (data.status === 200) {
                        this.$snotify.success(data.message, {
                            timeout: 100,
                            showProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false
                        });
                    }
                },
                err => {}
            );
        },
        addSpot() {
            console.log("add spot");
            this.$router.push({ name: "add_spot" });
        },
        paginationOption() {
            let perPageDropdown = [];
            let perPage = null
            console.warn('checking version id', this.version_id);

            if (this.version_id === 2 || this.version_id === 3) {
                perPageDropdown = [4, 20, 50, 100, 200];
                // to get the previously selected perPage value
                perPage = this.serverParams.perPage; 
            } else {
                perPageDropdown = [10, 20, 50, 100, 200];
                perPage = this.serverParams.perPage;
            }

            this.paginationOptions = {
                enabled: true,
                dropdownAllowAll: false,
                perPageDropdown: perPageDropdown
            };
        }
    },
    computed: {
        tableColumns() {
            let columns;
            if (this.version_id === 2 || this.version_id === 3) {
                columns = [
                    {
                        label: "Spot ID",
                        field: "spot_id",
                        filterable: true
                    },
                    {
                        label: "Date Spotted",
                        field: "spot_date",
                        sortable: true,
                        formatFn: this.formatDate
                    },
                    {
                        label: "User",
                        field: "user_name",
                        filterable: true
                    },
                    {
                        label: "Type",
                        field: "spot_name",
                        sortable: true
                    },
                    {
                        label: "Location",
                        field: "location_name",
                        sortable: true
                    },
                    {
                        label: "Type",
                        field: "media_types",
                        sortable: true,
                        formatFn: this.formatType
                    },
                    {
                        label: "Offline Spot",
                        field: "offline_mobile_spot",
                        sortable: true,
                        formatFn: this.OfflineModeStatus,
                        filterOptions: {
                            styleClass: 'class1',
                            enabled: true,
                            placeholder: '-',
                            filterValue: '',
                            filterDropdownItems: [
                                { value: '0', text: 'No' },
                                { value: '1', text: 'Yes' },
                            ],
                            filterFn: this.columnFilterFn,
                            trigger: 'enter',
                        },
                    }
                ];
                if (this.version_id === 2 && this.is_pro_sync === 1) {
                        columns.splice(7, 0, {
                            label: "Desktop Spot",
                            field: "desktop_spot",
                            sortable: true,
                            formatFn: this.desktopSpots,
                            filterOptions: {
                                styleClass: 'class1',
                                enabled: true,
                                placeholder: '-',
                                filterValue: '',
                                filterDropdownItems: [
                                    { value: '0', text: 'No' },
                                    { value: '1', text: 'Yes' },
                                ],
                                filterFn: this.columnFilterFn,
                                trigger: 'enter',
                            },
                        });
                }
                columns.push({
                    label: "Approve/ Action /Share",
                    field: "action",
                    sortable: false
                });

                return columns;

            }
            else {
                columns = [
                    {
                        label: "Spot ID",
                        field: "spot_id",
                        filterable: true
                    },
                    {
                        label: "Date Spotted",
                        field: "spot_date",
                        sortable: true,
                        formatFn: this.formatDate
                    },
                    {
                        label: "User",
                        field: "user_name",
                        filterable: true
                    },
                    {
                        label: "Type",
                        field: "spot_name",
                        sortable: true
                    },
                    {
                        label: "Location",
                        field: "location_name",
                        sortable: true
                    },
                    {
                        label: "Type",
                        field: "media_types",
                        sortable: true,
                        formatFn: this.formatType
                    },
                    {
                        label: "Approve/ Action /Share",
                        field: "action",
                        sortable: false
                    }
                ];
            }
            return columns;
        },
        tableColumns1() {
            let columns = [];
            let heading = this.Selected_Column_name;
            let selectedCategoryId = this.selectedCategory
            let CategorySelected = this.categories.find(category => category.id === selectedCategoryId);
            if (this.version_id === 2 || this.version_id === 3 ) {
                columns.push({
                    label: "Spot ID",
                    field: "spot_id",
                    filterable: true
                });
                columns.push({
                    label: "Date Spotted",
                    field: "spot_date",
                    sortable: true,
                    formatFn: this.formatDate
                });
                if(CategorySelected && CategorySelected.questions) {
                    let filterDropdownItems1 = JSON.parse(JSON.stringify(CategorySelected.questions)).map(question => ({
                        value: question.question_id,
                        text: question.question
                    }));
                    columns.push({
                        label: "", 
                        field : 'question_one',
                        sortable: true,
                        filterOptions: {
                            styleClass: 'class1',
                            enabled: true,
                            placeholder: this.$t('select'),
                            filterValue: '',
                            filterDropdownItems: filterDropdownItems1,
                            filterFn: false,
                        },
                    });
                } else {
                    columns.push({
                        label: "", 
                        field: 'question_one',
                        sortable: false,
                        filterOptions: {
                            styleClass: 'class1',
                            enabled: true,
                            placeholder: this.$t('select'),
                            filterValue: '',
                            filterDropdownItems: [{ value: '', text: this.$t('no-question-found') }],
                            filterFn: false,
                        },
                    });
                }
                if (CategorySelected && CategorySelected.questions) {
                    let filterDropdownItems2 = CategorySelected.questions.map(question => ({
                        value: question.question_id,
                        text: question.question
                    }));
                    columns.push({
                        label: "", 
                        field : 'question_two',
                        sortable: true,
                        filterOptions: {
                            styleClass: 'class1',
                            enabled: true,
                            placeholder: this.$t('select'),
                            filterValue: '',
                            filterDropdownItems: filterDropdownItems2,
                        },
                    });
                }else {
                    columns.push({
                        label: "", 
                        field: 'question_two',
                        sortable: false,
                        filterOptions: {
                            styleClass: 'class1',
                            enabled: true,
                            placeholder: this.$t('select'),
                            filterValue: '',
                            filterDropdownItems: [{ value: '', text: this.$t('no-question-found') }],
                            filterFn: false,
                        },
                    });
                }
                if (CategorySelected && CategorySelected.questions) {
                    let filterDropdownItems3 = CategorySelected.questions.map(question => ({
                        value: question.question_id,
                        text: question.question
                    }));
                    columns.push({
                        label: "", 
                        field : 'question_three',
                        sortable: true,
                        filterOptions: {
                            styleClass: 'class1',
                            enabled: true,
                            placeholder: this.$t('select'),
                            filterValue: '',
                            filterDropdownItems: filterDropdownItems3,
                        },
                    });
                }else {
                    columns.push({
                        label: "", 
                        field: 'question_three',
                        sortable: false,
                        filterOptions: {
                            styleClass: 'class1',
                            enabled: true,
                            placeholder: this.$t('select'),
                            filterValue: '',
                            filterDropdownItems: [{ value: '', text: this.$t('no-question-found') }],
                            filterFn: false,
                        },
                    });
                }
                if (CategorySelected && CategorySelected.questions) {
                    let filterDropdownItems4 = CategorySelected.questions.map(question => ({
                        value: question.question_id,
                        text: question.question
                    }));
                    columns.push({
                        label: "", 
                        field : 'question_four',                        
                        sortable: true,
                        filterOptions: {
                            styleClass: 'class1',
                            enabled: true,
                            placeholder: this.$t('select'),
                            filterValue: '',
                            filterDropdownItems: filterDropdownItems4,
                        },
                    });
                }else {
                    columns.push({
                        label: "", 
                        field: 'question_four',
                        sortable: false,
                        filterOptions: {
                            styleClass: 'class1',
                            enabled: true,
                            placeholder: this.$t('select'),
                            filterValue: '',
                            filterDropdownItems:  [{ value: '', text: this.$t('no-question-found') }],
                            filterFn: false,
                        },
                    });
                }   
                columns.push({
                    label: "Approve/ Action /Share",
                    field: "action",
                    sortable: false
                });
            }
            return columns;
        }
    },
    beforeMount(){
        // this.applyWeatherToSpots();
    },
    mounted() {
        this.isLoading = true;
        this.loadItems();
        this.loadItems1();
        this.getFilterCategorie();
        this.getUserDropdownOptionslist();
    },
    watch: {
        selectedCategory(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.handleCategorySelection(newVal);
            }
        },
    },
};
</script>

<style lang="scss">
.space-btw{
    justify-content: space-between;
}
.my-response-table table thead tr th:nth-child(3) {
    min-width: 100px !important;
}
.my-response-table table thead tr th:nth-child(4) {
    min-width: 250px !important;
    width: 20% !important;
    max-width: 20% !important;
}
.my-response-table table thead tr th:nth-child(5) {
    min-width: 125px !important;
}
@media (max-width: 1150px) {
    .card .new-card-body {
        padding: 1.25rem 1.437rem !important;
    }
}
@media (max-width: 500px) {
    .new-d-flex {
        flex-direction: column;
    }
    .new-d-flex button {
        width: fit-content;
        padding: 12px;
    } 
    .spot-btns {
        margin-top: 10px;
    }
    .spot-btns button {
        padding: 12px;
    }
}
@media (max-width: 450px) {
    .vgt-wrap__footer .footer__navigation__page-btn:nth-child(3) {
        float: right;
        margin-left: 8px;
    }
    .vgt-wrap__footer .footer__navigation__page-btn:nth-child(2) {
        margin-left: 0px;
        float: left;
    }
    .vgt-wrap__footer .footer__navigation__page-info {
        width: 100%;
        text-align: center;
        margin: 0;
    }
    .vgt-pull-right {
        float: inherit !important;
    }
}

</style>
